'use client';

import React, { useMemo, useCallback, useEffect } from 'react';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { type Block } from '@blocknote/core';

import { CheckIcon } from '@heroicons/react/solid';
import 'next-cloudinary/dist/cld-video-player.css';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useRouter } from 'next/router';
import { getCldOgImageUrl } from 'next-cloudinary';
import { useCookies } from 'react-cookie';
import {
  GetWebinarDataQuery,
  useRegisterForWebinarMutation,
  CurrentInvestorUserQuery,
} from '../../../apollo/generated';
import {
  BlockHeading,
  HubButton,
  Typography,
  Badge,
  DynamicRenderBlockNote,
  WebinarDocuments,
} from '../../../index';
import { useAlert } from '../../context/alert-context';
import CloudinaryImage from '../ui/cloudinary-image';
import { setReturnToCookie } from '../utils';
import { LiveWebinar } from './live-webinar';
import { WebinarQuestions } from './webinar-questions';
import { WebinarRecordingPlayer } from './webinar-recording-player';
import {
  getTimeRemaining,
  useTimeRemaining,
  formatDuration,
  getLocalDate,
  getWebinarBroadcastDuration,
  formatTimeRemaining,
} from './webinar-util';

dayjs.extend(utc);
dayjs.extend(timezone);

interface WebinarViewerProps {
  attendee?: NonNullable<
    NonNullable<GetWebinarDataQuery['webinarData']>['attendee']
  > | null;
  client?: ApolloClient<NormalizedCacheObject>;
  currentUser?: CurrentInvestorUserQuery['currentInvestorUser'];
  logoUrl?: string;
  name?: string;
  roomCodeOverride?: string | undefined;
  webinar: NonNullable<
    NonNullable<GetWebinarDataQuery['webinarData']>['webinar']
  >;
}

const formatWebinarTime = (
  date: string | null | undefined,
  timezone: string | null | undefined,
  format: string
) => {
  return getLocalDate(date, timezone).format(format);
};

const getWebinarDuration = (
  startTime: string | null | undefined,
  endTime: string | null | undefined,
  timezone: string | null | undefined
) => {
  const start = getLocalDate(startTime, timezone);
  const end = getLocalDate(endTime, timezone);
  return {
    hours: end.diff(start, 'hour'),
    minutes: end.diff(start, 'minute') % 60,
  };
};

export const WebinarViewer: React.FC<WebinarViewerProps> = ({
  attendee,
  client,
  currentUser,
  logoUrl,
  roomCodeOverride,
  webinar,
}) => {
  const isLoggedIn = !!currentUser;
  const [cookies] = useCookies(['_hermes_web_visitor']);

  // A room code means it's a URL shared to a speaker or moderator.
  // People with this link aren't expected to login to enter the webinar room.
  const canEnterRoomBecauseOfRoomcode =
    roomCodeOverride &&
    (webinar.state === 'scheduled' ||
      webinar.state === 'draft' ||
      webinar.state === 'live');

  const canAttendWebinar =
    webinar.type !== 'public' ||
    (webinar.attendanceNeedsLogin ? isLoggedIn : true);

  const webinarIsLive =
    (webinar.state === 'live' &&
      webinar.type !== 'recording_only' &&
      (isLoggedIn ? !!attendee : canAttendWebinar)) ||
    canEnterRoomBecauseOfRoomcode;

  const recordingIsAvailable =
    webinar.state === 'completed' && webinar.publishedRecordingUrl;

  const canWatchVideo = webinar.recordingNeedsLogin ? isLoggedIn : true;

  const timeRemaining = useTimeRemaining(
    webinar.startTime || '',
    webinar.timezone
  );

  const [registerMutation] = useRegisterForWebinarMutation({
    refetchQueries: ['GetWebinarData'],
  });

  const { formatAndShowError, showAlert } = useAlert();

  const handleRegister = useCallback(async () => {
    try {
      await registerMutation({ variables: { webinarId: webinar.id } });
      showAlert({
        description: 'Successfully registered for the webinar',
        variant: 'success',
      });
    } catch (error) {
      formatAndShowError(error);
    }
  }, [registerMutation, webinar.id, showAlert, formatAndShowError]);

  const maybeRegistering = useCallback(async () => {
    if (!attendee && recordingIsAvailable && canWatchVideo) {
      try {
        await registerMutation({ variables: { webinarId: webinar.id } });
      } catch (error) {
        formatAndShowError(error);
      }
    }
  }, [
    registerMutation,
    webinar.id,
    recordingIsAvailable,
    canWatchVideo,
    attendee,
    formatAndShowError,
  ]);

  useEffect(() => {
    maybeRegistering();
  }, [maybeRegistering]);

  const buildUrl = useMemo(() => {
    const baseUrl = `https://${
      process.env.NEXT_PUBLIC_HMS_SUBDOMAIN
    }.app.100ms.live/meeting/${roomCodeOverride || webinar.hmsViewerRoomCode}`;
    const params = new URLSearchParams();

    if (attendee && currentUser) {
      params.append('userId', attendee.id);
      params.append(
        'name',
        currentUser?.firstName + ' ' + currentUser?.lastName
      );
    } else if (cookies._hermes_web_visitor) {
      params.append('userId', cookies._hermes_web_visitor);
    }

    const queryString = params.toString();
    return queryString ? `${baseUrl}?${queryString}` : baseUrl;
  }, [
    attendee,
    roomCodeOverride,
    webinar.hmsViewerRoomCode,
    currentUser,
    cookies._hermes_web_visitor,
  ]);

  const formattedTimes = useMemo(() => {
    const useActualBroadcastTimes =
      webinar.type !== 'recording_only' &&
      webinar.startedBroadcastingAt &&
      webinar.stoppedBroadcastingAt;

    const startTimeStr = useActualBroadcastTimes
      ? webinar.startedBroadcastingAt
      : webinar.startTime;

    const endTimeStr = useActualBroadcastTimes
      ? webinar.stoppedBroadcastingAt
      : webinar.endTime;

    const startTime = getLocalDate(startTimeStr, webinar.timezone);
    const endTime = getLocalDate(endTimeStr, webinar.timezone);

    const { hours: durationHours, minutes: durationMinutes } =
      getWebinarDuration(startTimeStr, endTimeStr, webinar.timezone);

    return {
      durationHours,
      durationMinutes,
      endTime,
      startTime,
    };
  }, [
    webinar.startTime,
    webinar.endTime,
    webinar.timezone,
    webinar.startedBroadcastingAt,
    webinar.stoppedBroadcastingAt,
    webinar.type,
  ]);

  const renderBanner = () => {
    if (webinarIsLive) {
      return (
        <LiveWebinar
          buildUrl={buildUrl}
          duration={getWebinarBroadcastDuration(
            new Date(),
            formattedTimes.startTime.toDate()
          )}
          isLive={webinar.state === 'live'}
          isSpeakerLink={!!roomCodeOverride}
          shouldShowStatus={webinar.type !== 'recording_only'}
          title={webinar.title || 'Webinar'}
        />
      );
    }

    if (recordingIsAvailable && canWatchVideo) {
      return (
        <WebinarRecordingPlayer
          attendeeId={attendee?.id}
          posterImageUrl={webinar.posterImageUrl}
          publishedRecordingUrl={webinar.publishedRecordingUrl || ''}
          visitorCookieId={cookies._hermes_web_visitor}
          webinarId={webinar.id}
        />
      );
    }

    return (
      <WebinarPlaceholderPanel
        attendee={attendee}
        handleRegister={handleRegister}
        isLoggedIn={isLoggedIn}
        logoUrl={logoUrl}
        timeRemaining={timeRemaining}
        webinar={webinar}
      />
    );
  };

  return (
    <div className="flex flex-col">
      {renderBanner()}
      <div className="mx-auto w-full max-w-screen-xl px-4 py-8 sm:px-6">
        {webinar.state !== 'completed' && (
          <div className="mb-4 mt-2 flex items-center gap-2 text-gray-500">
            {webinar.state === 'live' && (
              <Badge state="positive" title="Live" variant="primary" />
            )}

            <Typography className="flex items-center" variant="body-small">
              {formattedTimes.startTime.format('DD/MM/YYYY')}
            </Typography>
            <Typography className="flex items-center" variant="body-small">
              {formattedTimes.startTime.format('h:mma')} -{' '}
              {formattedTimes.endTime.format('h:mma')}
              {webinar.state !== 'live' && webinar.state !== 'completed' && (
                <>
                  {' ('}
                  {formatDuration(
                    formattedTimes.durationHours,
                    formattedTimes.durationMinutes
                  )}
                  {')'}
                </>
              )}
            </Typography>
          </div>
        )}
        <BlockHeading>{webinar.title}</BlockHeading>
        {!!webinar.transcriptSummary && webinar.showTranscriptSummaryOnHub ? (
          <DynamicRenderBlockNote
            fullWidth
            content={
              (webinar.transcriptSummary as { content: Block[] }).content
            }
          />
        ) : (
          !!webinar.summary && (
            <DynamicRenderBlockNote
              fullWidth
              content={(webinar.summary as { content: Block[] }).content}
            />
          )
        )}
        <WebinarDocuments canDownload documents={webinar.documents} />
      </div>
      {webinar.allowPreWebinarComments ? (
        <WebinarQuestions
          client={client}
          hasStarted={['live', 'completed'].includes(webinar.state || '')}
          isLoggedIn={isLoggedIn}
          questions={attendee?.questions}
          webinar={webinar}
        />
      ) : null}
    </div>
  );
};

const getWebinarStatusText = (
  webinar: WebinarViewerProps['webinar']
): string => {
  if (webinar.state === 'live') {
    return 'Webinar is live now';
  }

  const now = getLocalDate(dayjs().toISOString(), webinar.timezone);
  const startTime = getLocalDate(webinar.startTime, webinar.timezone);

  return now.isAfter(startTime)
    ? 'Webinar will start soon'
    : 'Webinar starting in';
};

const WebinarPlaceholderPanel: React.FC<{
  attendee?: WebinarViewerProps['attendee'];
  handleRegister: () => Promise<void>;
  isLoggedIn: boolean;
  logoUrl?: string;
  timeRemaining: ReturnType<typeof getTimeRemaining>;
  webinar: WebinarViewerProps['webinar'];
}> = ({
  attendee,
  handleRegister,
  isLoggedIn,
  logoUrl,
  timeRemaining,
  webinar,
}) => {
  const router = useRouter();
  const renderThumbnail = () => {
    const displayImage = webinar.posterImageUrl
      ? webinar.posterImageUrl
      : webinar.imageCloudinaryId
      ? getCldOgImageUrl({
          src: webinar.imageCloudinaryId || '',
        })
      : null;
    return (
      <div className="hidden aspect-video h-[437px] items-center justify-center overflow-hidden rounded-lg bg-company-accent md:flex">
        {displayImage ? (
          <CloudinaryImage
            alt="Webinar thumbnail"
            className="h-full w-full object-cover"
            height={1080}
            src={displayImage}
            width={1920}
          />
        ) : (
          <CloudinaryImage
            alt="Webinar thumbnail"
            className="max-h-[120px] w-auto"
            height={120}
            src={logoUrl || ''}
            width={240}
          />
        )}
      </div>
    );
  };
  const finishedTime = getLocalDate(
    webinar.stoppedBroadcastingAt || webinar.endTime,
    webinar.timezone
  );

  const renderLoginComponent = () => {
    return (
      <div className={clsx('flex flex-col gap-4')}>
        <Typography className="text-center md:text-left" variant="body-regular">
          {`Log in or sign up to ${
            webinar.state === 'scheduled'
              ? 'register for'
              : webinar.state === 'live'
              ? 'join'
              : 'view'
          } this webinar`}
          {webinar.type === 'recording_only' &&
            ' and submit your questions below'}
        </Typography>
        <div className="flex items-center justify-center gap-4 md:justify-start">
          <HubButton
            isAccent
            label="Log in"
            url="/auth/signin"
            onClick={(e) => {
              e.preventDefault();
              setReturnToCookie(router.asPath);
              router.push('/auth/signin');
            }}
          />
          <HubButton
            isAccent
            label="Sign up"
            url="/auth/signup"
            onClick={(e) => {
              e.preventDefault();
              setReturnToCookie(router.asPath);
              router.push('/auth/signup');
            }}
          />
        </div>
      </div>
    );
  };
  const remainingTime =
    webinar.state === 'live'
      ? getLocalDate(webinar.endTime, webinar.timezone).diff(
          getLocalDate(dayjs().toISOString(), webinar.timezone),
          'second'
        )
      : timeRemaining.total / 1000;

  return (
    <div className="flex h-[650px] w-full bg-black py-16 text-company-primary-text md:px-8 md:py-10">
      <div className="flex h-full w-full items-center justify-center gap-8">
        <div className="z-10 flex w-full flex-col items-center space-y-16 rounded-none p-6 sm:rounded-lg md:h-[437px] md:w-[420px] md:items-start md:bg-[#1F1F23]">
          <div className="flex h-full flex-col justify-between space-y-4">
            <Typography
              className="line-clamp-2 max-h-[80px] min-h-fit overflow-hidden text-ellipsis text-center font-normal md:text-left"
              variant="heading-2"
            >
              {webinar.title}
            </Typography>
            {webinar.state === 'completed' ? (
              <>
                <div className="flex flex-col">
                  {!webinar.publishedRecordingUrl && (
                    <Typography
                      className="text-center font-normal md:text-left"
                      variant="display-small"
                    >
                      Webinar finished
                    </Typography>
                  )}
                  <Typography
                    className="mt-4 text-center font-normal md:text-left"
                    variant="body-regular"
                  >
                    {webinar.type === 'recording_only'
                      ? 'Webinar available:'
                      : 'Live streamed:'}
                  </Typography>
                  <Typography
                    className="mt-1 text-center font-normal md:text-left"
                    variant="body-regular"
                  >
                    {formatWebinarTime(
                      webinar.endTime,
                      webinar.timezone,
                      'DD/MM/YYYY'
                    )}
                    {' ('}
                    {finishedTime.fromNow()}
                    {')'}
                  </Typography>
                </div>
                {webinar.publishedRecordingUrl
                  ? isLoggedIn
                    ? null
                    : renderLoginComponent()
                  : null}
              </>
            ) : (
              <>
                <div className="flex flex-col">
                  <Typography
                    className="text-center font-normal md:text-left"
                    variant="display-small"
                  >
                    {getWebinarStatusText(webinar)}
                    {': '}
                  </Typography>
                  <Typography
                    className="text-center font-normal md:text-left"
                    variant="display-small"
                  >
                    {(webinar.state === 'scheduled' ||
                      webinar.state === 'draft') &&
                      `${formatTimeRemaining(remainingTime, 'Hold tight!')}`}
                    {webinar.state === 'live' &&
                      `${formatTimeRemaining(remainingTime)} remaining`}
                  </Typography>

                  <div className="mt-4 flex w-full flex-col">
                    <Typography
                      className=" text-center font-normal md:text-left"
                      variant="body-regular"
                    >
                      Scheduled start time:
                    </Typography>
                    <Typography
                      className="text-center font-normal md:text-left"
                      variant="body-regular"
                    >
                      {formatWebinarTime(
                        webinar.startTime,
                        webinar.timezone,
                        'DD/MM/YYYY'
                      )}
                      {' - '}
                      {formatWebinarTime(
                        webinar.startTime,
                        webinar.timezone,
                        'h:mma'
                      )}
                    </Typography>
                  </div>
                  <div className="mt-4 flex w-full flex-col">
                    <Typography
                      className=" text-center font-normal md:text-left"
                      variant="body-regular"
                    >
                      Duration:
                    </Typography>
                    <Typography
                      className="text-center font-normal md:text-left"
                      variant="body-regular"
                    >
                      {formatDuration(
                        getWebinarDuration(
                          webinar.startTime,
                          webinar.endTime,
                          webinar.timezone
                        ).hours,
                        getWebinarDuration(
                          webinar.startTime,
                          webinar.endTime,
                          webinar.timezone
                        ).minutes
                      )}
                    </Typography>
                  </div>
                </div>
                <>
                  {isLoggedIn ? (
                    attendee ? (
                      <div className="flex flex-col gap-4 md:flex-row">
                        <div className="mb-4 mt-[2px] flex items-center justify-center gap-2 rounded-[6px] border-2 border-company-primary-text px-4 py-3 ">
                          <div className="rounded-full bg-white">
                            <CheckIcon className="h-5 w-5 text-company-primary" />
                          </div>
                          <Typography variant="body-regular">
                            Registered
                          </Typography>
                        </div>
                        <div className="flex w-full justify-center md:justify-start">
                          <AddToCalendarButton
                            description={`Join the webinar at: ${window.location.origin}${router.asPath}`}
                            endDate={formatWebinarTime(
                              webinar.endTime,
                              webinar.timezone,
                              'YYYY-MM-DD'
                            )}
                            endTime={formatWebinarTime(
                              webinar.endTime,
                              webinar.timezone,
                              'HH:mm'
                            )}
                            location="Online"
                            name={webinar.title || 'Webinar'}
                            options={[
                              'Apple',
                              'Google',
                              'iCal',
                              'Outlook.com',
                              'Yahoo',
                            ]}
                            startDate={formatWebinarTime(
                              webinar.startTime,
                              webinar.timezone,
                              'YYYY-MM-DD'
                            )}
                            startTime={formatWebinarTime(
                              webinar.startTime,
                              webinar.timezone,
                              'HH:mm'
                            )}
                            styleDark="--font: inherit;"
                            styleLight=" --font: inherit;"
                            timeZone={webinar.timezone || 'UTC'}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="flex w-full justify-center md:justify-start">
                        <HubButton
                          isAccent
                          label={
                            webinar.state === 'live'
                              ? 'Join webinar'
                              : 'Register to attend'
                          }
                          onClick={handleRegister}
                        />
                      </div>
                    )
                  ) : (webinar.type === 'recording_only' &&
                      !webinar.recordingNeedsLogin) ||
                    !webinar.attendanceNeedsLogin ? null : (
                    renderLoginComponent()
                  )}{' '}
                </>
              </>
            )}
          </div>
        </div>
        {renderThumbnail()}
      </div>
    </div>
  );
};
