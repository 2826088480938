const getUrl = (value: string) => {
  try {
    const result = new URL(value);

    return result;
  } catch (error) {
    return false;
  }
};

export function formatVideoUrl(value: string) {
  const url = getUrl(value);
  if (!url) return false;

  if (url.hostname === 'player.vimeo.com') {
    return value;
  }

  switch (true) {
    case url.hostname.endsWith('youtube.com'):
      return formatYouTubeUrl(url);
    case url.hostname.endsWith('youtu.be'):
      return formatYouTubeShortUrl(url);
    case url.hostname.endsWith('vimeo.com'):
      return formatVimeoUrl(url);
    default:
      return false;
  }
}

function formatYouTubeUrl(url: URL) {
  // If it is shorts, convert to using normal player instead
  if (url.pathname.startsWith('/shorts')) {
    const id = url.pathname.split('/')[2];
    return `https://www.youtube.com/embed/${id}`;
  }

  if (url.pathname.startsWith('/embed')) {
    const id = url.pathname.split('/')[2];
    return `https://www.youtube.com/embed/${id}`;
  }

  const id = url.searchParams.get('v');
  return id ? `https://www.youtube.com/embed/${id}` : false;
}

function formatYouTubeShortUrl(url: URL) {
  const id = url.pathname.split('/')[1];
  return `https://www.youtube.com/embed/${id}`;
}

function formatVimeoUrl(url: URL) {
  const pathParts = url.pathname.split('/');
  const id = pathParts[1];
  const hash = pathParts.length > 2 ? pathParts[2] : null;
  return hash
    ? `https://player.vimeo.com/video/${id}?h=${hash}`
    : `https://player.vimeo.com/video/${id}`;
}

export function isYoutubeOrVimeoUrl(value?: string | null) {
  if (!value) return false;
  const url = getUrl(value);

  if (!url) return false;

  if (url.hostname.includes('youtube') || url.hostname.includes('youtu.be')) {
    return true;
  }

  if (url.hostname.includes('vimeo')) {
    return true;
  }

  return false;
}

export function getCloudinaryVideoPosterUrl(videoUrl?: string) {
  return videoUrl && videoUrl.includes('res.cloudinary.com')
    ? videoUrl
        .replace('/so_auto/', '/f_auto/g_auto/')
        .replace('/vc_auto/', '/')
        .replace('/fl_streaming/', '/')
        .replace('.mp4', '.jpg')
        .replace('.mov', '.jpg')
        .replace('.avi', '.jpg')
        .replace('.mkv', '.jpg')
    : undefined;
}
